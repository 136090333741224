html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

ol,
ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

/* end reset  */

a {
  cursor: pointer;
}

body {
  font-family: forma-djr-display, sans-serif;
  font-weight: 500;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.navToggledHeaderWrapper {
  background: #f9f9f9b8;
  border: 1px solid black;
  height: 212px;

  @media (min-width: 1500px) {
    height: 247px;
  }
}

.header-wrapper {
  position: fixed;
  right: 20px;
  top: 20px;
  z-index: 2;
}

nav {
  position: absolute;
  top: -300px;
  right: 5px;
  margin-top: 5px;
  width: 100%;

  li {
    margin: 2px 0;
    font-size: 20px;
    display: block;
    position: relative;
  }

  &.marginRevert {
    top: 100%;
    width: 95%;
  }
}

.locationIndicator {
  img {
    position: absolute;
  }

  .check::after {
    position: absolute;
    content: "";
    background: url("./assets/smallPepper.png");
    background-size: 100px;
    right: 0;
    height: 25px;
    width: 25px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.navToggled {
  color: white;
  background: black;
}

header {
  position: relative;
  cursor: pointer;

  h1 {
    font-size: 100px;
    font-weight: 700;
    line-height: 1;

    @media (min-width: 1500px) {
      font-size: 130px;
    }
  }
}

.photoNav {
  .mobileNav {
    position: fixed;
    mix-blend-mode: exclusion;
    width: 100%;
    top: 0;
    background: black;
    color: white;
  }

  .Photo {
    margin-top: 124px;
  }
}

.homeNav,
.contactNav {
  @media (max-width: 800px) {
    background-image: url("./assets/mobilePepper.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 100vh;
  }
}

.mobileNav {
  .mobileHeaderWrapper {
    background: black;
    padding: 5px 0 5px 15px;
  }

  h1 {
    color: white;
    font-size: 50px;
    font-weight: bold;
  }

  ul {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    margin: 15px;
  }

  li {
    &.check {
      text-decoration: underline;
    }
  }
}

.pepper {
  position: fixed;
  bottom: 0;
  height: 80vh;
  right: 30px;
  z-index: -1;
  object-fit: contain;
  pointer-events: none;
}

.footerQuote {
  font-size: 20px;
  font-style: italic;
  position: fixed;
  right: 20px;
  bottom: 20px;
  line-height: 1;
  text-align: right;

  span {
    display: block;
  }
}

.contactSection {
  margin: 100px auto 0;
  padding: 0 20px;
  position: relative;

  .textWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    z-index: 1;
  }

  .text {
    font-size: 30px;
    display: flex;
    align-items: flex-end;
    font-weight: normal;

    a {
      font-weight: 500;
      color: black;

      &:hover {
        color: white;
        background: black;
        mix-blend-mode: darken;
      }
    }
  }
}

.contentListWrapper {
  margin-left: 20px;
  margin-top: 20px;

  @media (min-width: 800px) {
    position: fixed;
    z-index: 1;
    width: 530px;
  }

  h2 {
    font-size: 32px;
    font-weight: 300;

    &:nth-of-type(2) {
      margin-top: 20px;
    }
  }

  .list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    max-width: 530px;
  }

  ul {
    font-size: 18px;
    font-weight: 400;
  }

  li {
    cursor: pointer;
  }
}

.selected {
  color: red;
}

.highlightedImgWrapper {
  position: fixed;
  left: 60%;
  top: 50%;
  transform: translate(-60%, -50%);
  background: #fff;
  padding: 10px;
  border: 1px solid black;

  @media (min-width: 1500px) {
    h3 {
      font-size: 25px;
    }
  }
}

.highlightedVidWrapper {
  position: fixed;
  height: 360px;
  width: 570px;
  left: 60%;
  top: 50%;
  transform: translate(-60%, -50%);
  background: #fff;
  padding: 10px;
  border: 1px solid black;

  @media (max-width: 800px) {
    width: 371px;
    height: 246px;
  }

  @media (max-width: 400px) {
    width: 95%;
    height: auto;
  }

  h3 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 20px;

    @media (min-width: 1500px) {
      font-size: 25px;
    }
  }

  .videoLink {
    cursor: pointer;
    text-decoration: none;
    color: inherit;
  }

  video {
    height: 304px;
    width: 549px;
    object-fit: cover;

    @media (max-width: 800px) {
      width: 349px;
      height: 191px;
    }

    @media (max-width: 400px) {
      width: 278px;
      height: 191px;
    }
  }

  img {
    min-width: 100%;
  }
}

.contact {
  display: block !important;

  a {
    font-weight: 500;
    color: black;

    &:hover {
      color: white;
      background: black;
      mix-blend-mode: darken;
    }
  }
}

.comingSoon {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, 50%);
  text-shadow: 0px 20px 3px #92ff00;
  -webkit-animation: shadowColorChange 15s infinite;
  -moz-animation: shadowColorChange 15s infinite;
  -o-animation: shadowColorChange 15s infinite;
  animation: shadowColorChange 15s infinite;
}

@-webkit-keyframes shadowColorChange {
  0% {
    text-shadow: 0px 20px 3px #92ff00;
  }

  25% {
    text-shadow: 0px 20px 3px #ff00e6;
  }

  75% {
    text-shadow: 0px 20px 3px #0037ff;
  }

  100% {
    text-shadow: 0px 20px 3px #92ff00;
  }
}

@-moz-keyframes shadowColorChange {
  0% {
    text-shadow: 0px 20px 3px #92ff00;
  }

  25% {
    text-shadow: 0px 20px 3px #ff00e6;
  }

  75% {
    text-shadow: 0px 20px 3px #0037ff;
  }

  100% {
    text-shadow: 0px 20px 3px #92ff00;
  }
}

@-o-keyframes shadowColorChange {
  0% {
    text-shadow: 0px 20px 3px #92ff00;
  }

  25% {
    text-shadow: 0px 20px 3px #ff00e6;
  }

  75% {
    text-shadow: 0px 20px 3px #0037ff;
  }

  100% {
    text-shadow: 0px 20px 3px #92ff00;
  }
}

@keyframes shadowColorChange {
  0% {
    text-shadow: 0px 20px 3px #92ff00;
  }

  25% {
    text-shadow: 0px 20px 3px #ff00e6;
  }

  75% {
    text-shadow: 0px 20px 3px #0037ff;
  }

  100% {
    text-shadow: 0px 20px 3px #92ff00;
  }
}

.mobileContact {
  padding: 0 15px 15px;

  h3 {
    font-size: 20px;

    a {
      font-weight: 500;
      color: white;
      background: black;
      mix-blend-mode: darken;
    }
  }

  img {
    margin-top: 15px;
  }
}

.d-none {
  display: none !important;
}

.gallery {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;

  img {
    height: 100%;
    width: auto;
    object-fit: contain;
  }

  .imageWrapper {
    margin-right: 15px;
    flex: 0 0 auto;
    max-height: 80vh;
  }

  @media (max-width: 800px) {
    flex-direction: column;

    img {
      margin: 0 auto 15px auto;
      width: 90vw;
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.partyWrapper {
  padding: 10px;
  max-width: 90%;
  background: black;
  border: 1px solid black;
  margin: 0 auto 100px auto;
  width: fit-content;

  &:nth-of-type(even) {
    max-width: 70%;
    background: blue;
  }

  &:nth-child(4n-7) {
    background: #00ff43;
  }

  video {
    max-width: 100%;
  }
}

.iframeWrapper {
  margin: auto;
}

@media (max-width: 800px) {
  .canvas-container {
    display: none !important;
  }

  .header-wrapper {
    position: static;
    margin: 15px 15px 20px;

    h1 {
      font-size: 70px;
    }
  }

  .contentListWrapper {
    margin-left: 15px;
    margin-top: 0;

    ul {
      font-size: 16px;

      li {
        line-height: 1.5;
        white-space: nowrap;
      }
    }

    .list {
      margin-bottom: 40px;
    }
  }

  .footerQuote {
    display: none;
  }

  .highlightedVidWrapper,
  .highlightedImgWrapper {
    h3 {
      font-size: 18px;
    }
  }

  .pepper {
    right: 0;
  }

  .gallery .imageWrapper {
    margin: auto;
  }
}